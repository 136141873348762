
<!-- Güncelleme kontrolü için ayrı bir ng-container kullanın -->
<ng-container *ngIf="updateCheckService.updateAvailable$ | async as isUpdateAvailable">
  <update-modal *ngIf="isUpdateAvailable" (countdownFinished)="updateCheckService.applyUpdate()"
    [countdownSeconds]="10"></update-modal>
</ng-container>





<!-- router-outlet'i her zaman görünür hale getirin -->

<div class="absolute h-screen bg-header left-0 right-0 mx-auto overflow-hidden"
  *ngIf="backgroundService.currentBackground | async as currentBackground" [class]="currentBackground.class"
  [ngStyle]="{'background-color':currentBackground.bgColor,'background-image':'url(' +currentBackground.image+')','transform':'scale(1)', 'transition': 'background-image 1s ease-in-out'}">
</div>


<div class="absolute inset-0 bg-black opacity-20"></div>

<router-outlet [class.hidden]="updateCheckService.updateAvailable$ | async"></router-outlet>