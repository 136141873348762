
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';

import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { SubSink } from './core/subsink';

import { FuseSplashScreenService } from '@fuse/services/splash-screen';
import { SettingsService } from './core/services/settings/settings.service';
import { BadisOauthLoginService } from './core/services/auth/badis-oauth-login.service';
import { Router } from '@angular/router';
import { SettingsModel } from './core/services/settings/dtos/settings-model';
import { NGXLogger, NgxLoggerLevel } from 'ngx-logger';
import { fuseAnimations } from '@fuse/animations';
import { LoggerMonitor } from './shared/services/logger-monitor.service';


import { DeviceHubConnectionService } from './core/services/hubs/device-hub-connection.service';
import { PropertyHubConnectionService } from './core/services/hubs/property-hub-connection.service';
import { LocationHubConnectionService } from './core/services/hubs/location-hub-connection.service';
import { SystemHubConnectionService } from './core/services/hubs/system-hub-connection.service';
import { AlertHubConnectionService } from './core/services/hubs/alert-hub-connection.service';
import { PostOfficeService } from './core/services/post-office/post-office.service';
import { LanguageService } from './shared/services/language.service';
import { LiveDTO } from './modules/live/dtos/live';
import { NotificationService } from './pages/notifications/services/notification.service';
import { Title } from '@angular/platform-browser';
import { UpdateCheckService } from './shared/services/update-check.service';
import { BackgroundService } from '@app/shared/services/background.service';
import { GlobalVariablesService } from './core/services/global-variables.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import calendar from 'dayjs/plugin/calendar';
import localeData from 'dayjs/plugin/localeData';
import duration from 'dayjs/plugin/duration';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/tr' // import locale
import { MaintenanceHubConnectionService } from './core/services/hubs/maintenance-hub-connection.service';
import { Maintenance } from './core/services/hubs/models/maintenance.model';
import { ServiceName, ServiceReadyStateService } from './core/services/service-ready-state.service';

@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: fuseAnimations
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {


    private subs = new SubSink();

    enableAlerts: boolean = false;
    newUpdateAvailable: boolean;
    enablePushAlerts: boolean = false;

    constructor(
        public library: FaIconLibrary,

        private splash: FuseSplashScreenService,
        private loginService: BadisOauthLoginService,
        private settingService: SettingsService,
        logger: NGXLogger,

        private deviceHub: DeviceHubConnectionService,
        private propertyHub: PropertyHubConnectionService,
        private locationHub: LocationHubConnectionService,
        private systemHub: SystemHubConnectionService,
        private alertHub: AlertHubConnectionService,
        private maintenanceHub: MaintenanceHubConnectionService,
        public backgroundService: BackgroundService,
        private postOfficeService: PostOfficeService,
        languageService: LanguageService,
        public updateCheckService: UpdateCheckService,
        private titleService: Title,
        private notificationService: NotificationService,
        global: GlobalVariablesService,
        private readyStateService: ServiceReadyStateService
    ) {

        // dayjs initialization
        dayjs.extend(utc);
        dayjs.extend(timezone);
        dayjs.extend(calendar);
        dayjs.extend(localeData);
        dayjs.extend(duration);
        dayjs.extend(isLeapYear);
        dayjs.extend(advancedFormat);
        dayjs.extend(localizedFormat);


        languageService.init();

        this.splash.show();

        this.notificationService.getNotificationsData().subscribe(data => {
            this.updateTitle(data.unreadMessages);
        });

        logger.registerMonitor(new LoggerMonitor());

        this.library.addIconPacks(far);
        this.library.addIconPacks(fal);
        this.library.addIconPacks(fas);

        this.loginService.onAuthStatusChanged.subscribe(async (isAuthenticated: boolean) => {

            if (isAuthenticated && this.loginService.isUserLoggedIn()) {
                await this.pageIsReady();

            } else {

                //redirect to login page with return url
                //this.router.navigate(['/auth', { returnUrl: this.router.url }]);
            }
            this.settingService.refresh();
        });

        this.settingService.settingChanged.subscribe((setting: SettingsModel.Settings) => {
            switch (setting.primaryKey) {
                case 'Performance.Browser.EnableDebug':
                    const enableDebug = setting.userSetting.primaryValue;

                    console.debug('debug mode:', enableDebug ? 'true' : 'false');
                    const config = logger.getConfigSnapshot();
                    config.level = enableDebug ? NgxLoggerLevel.TRACE : NgxLoggerLevel.ERROR;
                    config.serverLogLevel = NgxLoggerLevel.ERROR;
                    config.disableConsoleLogging = !enableDebug;
                    logger.updateConfig(config);
                    global.isDebugMode = enableDebug;
                    break;
                case 'General.Region.TimeZone':
                    const timeZone = setting.userSetting.primaryValue || 'Europe/Istanbul';
                    dayjs.tz.setDefault(timeZone);
                    break;
                case 'Notification.Alerts.Enable':
                    this.enableAlerts = setting.userSetting.getValue<boolean>();
                    break;


                default:
                    break;
            }


            if (setting.changeRequest === LiveDTO.ChangeRequest.User) {

                switch (setting.primaryKey) {


                }
            }


        });





    }

    async pageIsReady() {

        this.postOfficeService.init();

        this.notificationService.handleTokenRefresh();

        this.deviceHub.init();
        this.propertyHub.init();
        this.locationHub.init();
        this.systemHub.init();
        this.alertHub.init();
        this.maintenanceHub.init();

        await this.deviceHub.startConnection();
        await this.propertyHub.startConnection();
        await this.locationHub.startConnection();
        await this.systemHub.startConnection();
        await this.alertHub.startConnection();
        await this.maintenanceHub.startConnection();
        this.readyStateService.setServiceReady(ServiceName.SignalR, true);




        this.alertHub.alertUpdated$
            .pipe(untilDestroyed(this))
            .subscribe((alert) => {
                if (!alert || !this.enableAlerts || this.enablePushAlerts)
                    return;

                this.notificationService.showAlert(alert);
            });

        this.maintenanceHub.fragmentationProgress$.subscribe((data) => {
            if (!data)
                return;
            if (data.status == Maintenance.MaintenanceStatus.Started) {
                this.notificationService.showMaintenanceAlert(data);
            }

        });

        this.splash.hide();

    }




    ngOnInit(): void {

        // todo: mesut -> if something goes wrong! delete me
        // this.screenLock.startWatching(5).subscribe((isTimedOut: boolean) => {
        //     if (isTimedOut) {
        //      //   console.log('screen locked! just kidding:) not today but soon');
        //     }
        // });
    }

    private updateTitle(unreadCount: number): void {
        const newTitle = unreadCount > 0 ? `(${unreadCount}) Navizard` : 'Navizard';
        this.titleService.setTitle(newTitle);
    }


    ngAfterViewInit(): void {
        this.updateCheckService.checkForUpdates();

    }


    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
