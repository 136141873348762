import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Background } from './models/background';

@Injectable({
  providedIn: 'root'
})
export class BackgroundService {
  private backgrounds: Background[] = [
    new Background(1, 'assets/images/background/header/page-header-bg1.png', '#38313d', '#ffffff', 'lg:bg-contain'),
    new Background(2, 'assets/images/background/header/page-header-bg2.png', '#ffffff', '#ffffff', 'lg:bg-contain'),
    new Background(3, 'assets/images/background/header/page-header-bg3.png', '#0f1224', '#ffffff', 'lg:bg-contain'),
    new Background(4, 'assets/images/background/header/page-header-bg4.png', '#2f1813', '#ffffff', 'lg:bg-contain'),
    new Background(5, 'assets/images/background/header/page-header-bg6.png', '#050000', '#ffffff', 'lg:bg-contain'),
    new Background(6, 'assets/images/background/header/page-header-bg7.png', '#ffffff', '#ffffff', 'lg:bg-contain'),
    new Background(7, 'assets/images/background/header/page-header-bg8.png', '#121620', '#ffffff', 'lg:bg-contain'),
    new Background(8, 'assets/images/background/header/page-header-bg9.png', '#b1aba3', '#ffffff', 'lg:bg-contain'),
    new Background(9, 'assets/images/background/header/page-header-bg10.png', '#ffffff', '#ffffff', 'lg:bg-contain'),
    new Background(10, 'assets/images/background/header/page-header-bg12.png', '#ffffff', '#ffffff', 'lg:bg-contain'),
    new Background(11, 'assets/images/background/header/page-header-bg13.png', '#ffffff', '#ffffff', 'lg:bg-contain'),
    new Background(12, 'assets/images/background/header/page-header-bg14.png', '#916a95', '#ffffff', 'lg:bg-contain'),
    new Background(13, 'assets/images/background/header/page-header-bg15.png', '#ffffff', '#ffffff', 'lg:bg-contain'),
    new Background(14, 'assets/images/background/header/page-header-bg16.png', '#ffffff', '#ffffff', 'lg:bg-contain'),
    new Background(15, 'assets/images/background/header/page-header-bg19.png', '#433b5c', '#ffffff', 'lg:bg-contain'),
    new Background(16, 'assets/images/background/header/page-header-bg20.png', '#22516b', '#ffffff', 'lg:bg-contain'),
    new Background(17, 'assets/images/background/header/page-header-bg21.png', '#384a54', '#ffffff', 'lg:bg-contain'),
    new Background(18, 'assets/images/background/header/page-header-bg22.png', '#0e202b', '#ffffff', 'lg:bg-contain'),
    new Background(19, 'assets/images/background/header/page-header-bg23.png', '#232c2f', '#ffffff', 'lg:bg-contain'),
    new Background(20, 'assets/images/background/header/page-header-bg24.png', '#347a7a', '#ffffff', 'lg:bg-contain'),
    new Background(21, 'assets/images/background/header/page-header-bg25.png', '#202c2e', '#ffffff', 'lg:bg-contain'),
    new Background(22, 'assets/images/background/header/page-header-bg26.png', '#ffffff', '#ffffff', 'lg:bg-contain'),
    new Background(23, 'assets/images/background/header/page-header-bg27.png', '#000000', '#ffffff', 'lg:bg-contain'),
    new Background(24, 'assets/images/background/header/page-header-bg28.png', '#ffffff', '#ffffff', 'lg:bg-contain'),

    new Background(25, 'assets/images/background/header/page-header-full-bg01.jpg', '#1a272a', '#ffffff', 'bg-cover'),
    new Background(26, 'assets/images/background/header/page-header-full-bg02.jpg', '#1a272a', '#ffffff', 'bg-cover'),
    new Background(27, 'assets/images/background/header/page-header-full-bg03.jpg', '#1a272a', '#ffffff', 'bg-cover'),

    new Background(28, 'assets/images/background/header/page-header-full-bg04.jpg', '#1a272a', '#ffffff', 'bg-cover'),
    new Background(29, 'assets/images/background/header/page-header-full-bg05.jpg', '#1a272a', '#ffffff', 'bg-cover'),
   
    new Background(30, 'assets/images/background/header/page-header-full-bg06.jpg', '#1a272a', '#ffffff', 'bg-cover'),
    new Background(31, 'assets/images/background/header/page-header-full-bg07.jpg', '#1a272a', '#ffffff', 'bg-cover'),
    new Background(32, 'assets/images/background/header/page-header-full-bg08.jpg', '#1a272a', '#ffffff', 'bg-cover'),
    new Background(33, 'assets/images/background/header/page-header-full-bg09.jpg', '#1a272a', '#ffffff', 'bg-cover'),
    new Background(34, 'assets/images/background/header/page-header-full-bg10.jpg', '#1a272a', '#ffffff', 'bg-cover'),
    new Background(35, 'assets/images/background/header/page-header-full-bg11.jpg', '#1a272a', '#ffffff', 'bg-cover'),
    new Background(36, 'assets/images/background/header/page-header-full-bg12.jpg', '#1a272a', '#ffffff', 'bg-cover'),
    new Background(37, 'assets/images/background/header/page-header-full-bg13.webp', '#1a272a', '#ffffff', 'bg-cover'),
    new Background(38, 'assets/images/background/header/page-header-full-bg14.webp', '#1a272a', '#ffffff', 'bg-cover'),
    new Background(39, 'assets/images/background/header/page-header-full-bg15.webp', '#1a272a', '#ffffff', 'bg-cover'),
    new Background(40, 'assets/images/background/header/page-header-full-bg16.webp', '#1a272a', '#ffffff', 'bg-cover'),
    new Background(41, 'assets/images/background/header/page-header-full-bg17.webp', '#1a272a', '#ffffff', 'bg-cover'),
    new Background(42, 'assets/images/background/header/page-header-full-bg18.webp', '#1a272a', '#ffffff', 'bg-cover'),

  ];

  public isTransitioning: boolean = false;
  public currentBackground: BehaviorSubject<Background> = new BehaviorSubject(this.backgrounds[0]);

  constructor() {
    this.setBackground(17); // Başlangıçta belirli bir arka planı ayarlayın
  }

 
  // Resim ön yükleme fonksiyonu
  preloadImage(url: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => resolve();
      img.onerror = reject;
    });
  }

 
  public setBackground(index?: number): void {
    this.isTransitioning = true;

    let selectedBackground: Background;

    if (typeof index === 'number' && index >= 0 && index <= this.backgrounds.length) {
      selectedBackground = this.backgrounds.find(bg => bg.index === index);
    } else {
      const randomIndex = Math.floor(Math.random() * this.backgrounds.length);
      selectedBackground = this.backgrounds[randomIndex];
    }

    // Yeni resmi ön yükle
    if (selectedBackground) {
      this.preloadImage(selectedBackground.image).then(() => {
        // Yükleme tamamlandığında yeni arka planı ayarla
        this.currentBackground.next(selectedBackground);
        this.isTransitioning = false;
      }).catch(error => {
        // Hata durumunda işlem
        this.isTransitioning = false;
      });
    }
  }


}
