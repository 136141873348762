import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalVariablesService } from '../../../core/services/global-variables.service';
import { Guid } from '../../../core/constants/constants';
import { CustomReportEditor } from './models/custom-report-editor.model';

@Injectable()
export class CustomReportService {

    public customReports: CustomReportEditor.CustomReport[] = []; // Custom reports array

    constructor(
        private http: HttpClient,
        private varService: GlobalVariablesService) { }


    // Fetch user custom reports
    public getUserCustomReports(): Observable<CustomReportEditor.CustomReport[]> {
        const url = `${this.varService.apiV2Address}/reports/custom-reports`;
        return this.http.get<CustomReportEditor.CustomReport[]>(url); // Change 'any' to 'CustomReport'
    }

    // Add a new custom report
    public addCustomReport(report: CustomReportEditor.CustomReport): Observable<CustomReportEditor.CustomReport> {
        const url = `${this.varService.apiV2Address}/reports/custom-reports`;
        return this.http.post<CustomReportEditor.CustomReport>(url, report);
    }

    // Update an existing custom report
    public updateCustomReport(report: CustomReportEditor.CustomReport): Observable<CustomReportEditor.CustomReport> {
        const url = `${this.varService.apiV2Address}/reports/custom-reports/${report.id}`;
        return this.http.put<CustomReportEditor.CustomReport>(url, report);
    }

    // Delete a custom report
    public deleteCustomReport(reportId: Guid): Observable<CustomReportEditor.CustomReport> {
        const url = `${this.varService.apiV2Address}/reports/custom-reports/${reportId}`;
        return this.http.delete<CustomReportEditor.CustomReport>(url);
    }
}

