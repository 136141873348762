import { CommonModule } from '@angular/common';

import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



import { MAT_DATE_LOCALE } from '@angular/material/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxTippyModule } from 'ngx-tippy-wrapper';

import {

    FilterPipe,
    GlobalEnumPipe,
    HideTextPipe,
    OrderByPipe,
    TimeDurationsPipe
} from './pipe';

import { MaterialModule } from '@app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { NoRecordFoundComponent } from './components/no-record-found/no-record-found.component';
import { RtlsToolBarModule } from './components/rtls-tool-bar/rtls-tool-bar.module';

import { SignalRStateComponent } from './components/signalr-state/signalr-state.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';

import { SpinnerOverlayComponent } from './components/spinner/component/spinner-overlay.component';

import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { TagItemPropertyTagDetailsComponent } from './components/tag-details/tag-item-property-tag-details.component';


import { UiSwitchModule } from 'ngx-ui-switch';
import { CrudActionButtonsComponent } from './components/crud-action-buttons/crud-action-buttons.component';
import { TagReportFiltersComponent } from './components/tags/report-filters/tag-report-filters/tag-report-filters.component';
import { RangeSwitchCaseDirective } from './directives/range-switch-case.directive';
import { VarDirective } from './directives/variable.directive';

import { RouterModule } from '@angular/router';

import { DetailPageHeaderComponent } from './components/detail-page-header/detail-page-header.component';
import { RtlsCountPieGraphicModule } from './components/rtls-count-pie-graphic/rtls-count-pie-graphic.module';

import { AutoFlightSwitchComponent } from '@app/modules/live/components/auto-flight-switch/auto-flight-switch.component';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';

import { PostButtonExecutingModule } from './directives/post-button-executing/post-button-executing.module';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

import { IconResolverComponent } from './components/icon-resolver/icon-resolver.component';
import { IconSelectorComponent } from './components/icon-selector/icon-selector.component';
import { FaIconListComponent } from './components/icon-selector/components/fa-icon-list/fa-icon-list.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { BaseComponent } from './components/base/base.component';
import { ListPageBaseComponent } from './components/list-page-base/list-page-base.component';
import { TableBaseComponent } from './components/table-base/table-base.component';
import { DevExtremesModule } from './dev-extreme.module';
import { BaseColumnDirective } from './components/table-base/directives/base-column.directive';
import { FuseScrollbarModule } from '@fuse/directives/scrollbar';
import { DrawDetailContainerComponent } from './components/draw-detail-container/draw-detail-container.component';
import { ItemPropertyComponent } from './components/item-property/item-property.component';
import { ItemPropertyContainerComponent } from './components/item-property-container/item-property-container.component';
import { ProgressItemComponent } from './components/progress-item/progress-item.component';
import { MapItemComponent } from './components/map-item/map-item.component';
import { BakelorMapModule } from 'bakelor-map';

import { ToggleButtonComponent } from './components/toggle-button/toggle-button.component';
import { RecordBaseItemComponent } from './components/record-base-item/record-base-item.component';
import { TruncatePipesModule } from './pipe/truncate/truncate-pipes.module';
import { AutocompleteOffDirective } from './directives/autocomplete-off.directive';

import { LetModule } from '@ngrx/component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { VirtualDropDownComponent } from './components/virtual-drop-down/virtual-drop-down.component';
import { NgSelectModule } from '@ng-select/ng-select';

import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageEditorComponent } from './components/image-editor/image-editor.component';
import { ImageUploaderComponent } from './components/image-editor/components/image-uploader/image-uploader.component';
import { DropZoneDirective } from './directives/drop-zone.directive';
import { CoordinateEditorComponent } from './components/coordinate-editor/coordinate-editor.component';
import { DateRangeComponent } from './date-range/date-range.component';

import { NavizardLogoComponent } from './components/navizard-logo/navizard-logo.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { SecurityAlertComponent } from './toasts/security-alert/security-alert.component';
import { UpdateModalComponent } from './components/update-modal/update-modal.component';
import { DebugContainerComponent } from './components/debug-container/debug-container.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { TagOnlineIndicatorComponent } from './components/tag-online-indicator/tag-online-indicator.component';
import { BarcodeEditComponent } from './components/barcode-edit/barcode-edit.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { RelativeTimePipe } from './pipe/relative-time.pipe';
import { CalendarTimePipe } from './pipe/calendar-time.pipe';
import { DatetimeFormatPipe } from './pipe/datetime-format.pipe';
import { DurationTimePipe } from './pipe/duration-time.pipe';
import { MapDesignerComponent } from './components/map-designer/map-designer.component';
import { MapDesignerDialogComponent } from './components/map-designer/components/map-designer-dialog/map-designer-dialog.component';
import { AnimatedTextComponent } from './components/animated-text/animated-text.component';
import { TextRevealComponent } from './components/text-reveal/text-reveal.component';
import { CustomReportEditorComponent } from './components/custom-report-editor/custom-report-editor.component';
import { DxNumberBoxModule } from 'devextreme-angular/ui/number-box';
import { DxSwitchModule } from 'devextreme-angular/ui/switch';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { NorthernLightsComponent } from './components/northern-lights/northern-lights.component';
import { NgxColorsModule } from 'ngx-colors';

@NgModule({
    declarations: [
        FilterPipe,
        TimeDurationsPipe,
        DatetimeFormatPipe,

        GlobalEnumPipe,
        HideTextPipe,

        OrderByPipe,
        VarDirective,


        RangeSwitchCaseDirective,
        TooltipComponent,
        TagItemPropertyTagDetailsComponent,

        SpinnerOverlayComponent,
        SignalRStateComponent,
        NoRecordFoundComponent,

        CrudActionButtonsComponent,
        TagReportFiltersComponent,

        DetailPageHeaderComponent,

        ToggleSwitchComponent,
        AutoFlightSwitchComponent,

        IconResolverComponent,
        CustomReportEditorComponent,

        IconSelectorComponent,
        FaIconListComponent,
        BaseComponent,
        ListPageBaseComponent,
        TableBaseComponent,
        BaseColumnDirective,
        DrawDetailContainerComponent,
        ItemPropertyComponent,
        ItemPropertyContainerComponent,
        ProgressItemComponent,
       
        ToggleButtonComponent,
        RecordBaseItemComponent,

        AutocompleteOffDirective,
        VirtualDropDownComponent,

        ImageEditorComponent,
        ImageUploaderComponent,
        DropZoneDirective,
        CoordinateEditorComponent,
        DateRangeComponent,

        NavizardLogoComponent,
        DropdownComponent,
        SecurityAlertComponent,
        UpdateModalComponent,
        DebugContainerComponent,
        TagOnlineIndicatorComponent,
        BarcodeEditComponent,
        RelativeTimePipe,
        CalendarTimePipe,
        DatetimeFormatPipe,

        DurationTimePipe,
        MapItemComponent,
        MapDesignerComponent,
        MapDesignerDialogComponent,
        AnimatedTextComponent,
        TextRevealComponent,
        CustomReportEditorComponent,
        NorthernLightsComponent
        
    ],
    imports: [
        CommonModule,

        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        RouterModule,


        NgxSliderModule,

        UiSwitchModule,

        NgxDaterangepickerMd.forRoot(),

        TranslateModule.forChild(),
        MaterialModule,

        RtlsToolBarModule,
        NgScrollbarModule,

        LetModule,

        NgxColorsModule,

        PostButtonExecutingModule,

        RtlsCountPieGraphicModule,

        NgxTippyModule,
        ScrollingModule,
        DevExtremesModule,
        FuseScrollbarModule,
        BakelorMapModule,
        TruncatePipesModule,
        MatFormFieldModule,
        NgSelectModule,
        ImageCropperModule,
        ZXingScannerModule,
        DxNumberBoxModule,
        DxSwitchModule,
        DxButtonModule
  
    ],
    exports: [
        CommonModule,
        RouterModule,

        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        TranslateModule,



        MaterialModule,


        VarDirective,
    

        RangeSwitchCaseDirective,

        RtlsToolBarModule,

        FilterPipe,
        TimeDurationsPipe,
        DatetimeFormatPipe,

        GlobalEnumPipe,
        HideTextPipe,
        OrderByPipe,

        DetailPageHeaderComponent,
        TooltipComponent,

        AutocompleteOffDirective,

        TagReportFiltersComponent,
        SpinnerOverlayComponent,
        SignalRStateComponent,
        NoRecordFoundComponent,
        CrudActionButtonsComponent,
        IconResolverComponent,
        CustomReportEditorComponent,
        TagItemPropertyTagDetailsComponent,

        TruncatePipesModule,
        RtlsCountPieGraphicModule,

        ToggleSwitchComponent,
        AutoFlightSwitchComponent,

        PostButtonExecutingModule,

        IconSelectorComponent,
        LetModule,
        TranslateModule,
        NgScrollbarModule,

        // BaseComponents
        ListPageBaseComponent,
        TableBaseComponent,
        BaseColumnDirective,
        FuseScrollbarModule,
        DrawDetailContainerComponent,
        ItemPropertyContainerComponent,
        ItemPropertyComponent,
        ProgressItemComponent,
   
        ToggleButtonComponent,
        RecordBaseItemComponent,
        VirtualDropDownComponent,
        ImageEditorComponent,
        CoordinateEditorComponent,
        NgxDaterangepickerMd,
        DateRangeComponent,
        NavizardLogoComponent,
        UpdateModalComponent,
        DebugContainerComponent,
        TagOnlineIndicatorComponent,
        SecurityAlertComponent, BarcodeEditComponent,
        RelativeTimePipe, CalendarTimePipe,
        DatetimeFormatPipe,
        DurationTimePipe,
        MapItemComponent,
        MapDesignerComponent,
        MapDesignerDialogComponent,
        AnimatedTextComponent,
        TextRevealComponent,
        NorthernLightsComponent

    ],
    providers: [
        TimeDurationsPipe,

        //     { provide: TableIntl, useFactory: languageIntl },
        { provide: MAT_DATE_LOCALE, useValue: 'tr-TR' },

    ],
})
export class SharedModule {

    // constructor(@Optional() @SkipSelf() parentModule: SharedModule) {
    //     if (parentModule) {
    //         throw new Error('SharedModule is already loaded. Please add it in AppModule only.');
    //     }
    // }

    /**
     *
     */
    constructor() {



    }
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: []
        };
    }

}
